/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html,
#root {
  height: 100%;
  font-family: Arial, sans-serif;
  font-size: 14px;
  /* background-color: #94d9cd; */
  background-image: url('../public/assets/images/rm141.jpg');
  background-size: cover;
}

/* Sidebar Styles */
.sidebar {
  color: white;
  height: 100vh;
  background: linear-gradient(165deg, #6f6af8, #4f186b);
  transition: width 0.3s ease;
  transform: 0.3s ease;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  overflow-y: scroll;
  scrollbar-width: none;
  /* Ensure sidebar is above main content when necessary */
}

/* .sidebar.collapsed {
  width: 60px;
}

.sidebar:not(.collapsed) {
  width: 250px;
}

.sidebar .toggle-button {
  position: absolute;
  top: 10px;
  right: -10px;
  width: 35px;
  height: 25px;
  background-color: cornflowerblue;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 20;
  border-radius: 20px;
  display: none;
} */

.sidebar {
  width: 250px;
  transition: width 0.3s;
}

.sidebar.collapsed {
  width: 0;
}

.toggle-button {
  display: none;
  /* position: fixed; */
  top: 10px;
  left: 10px;
  z-index: 1000;
}

@media (max-width: 768px) {

  .toggle-button {
    display: block;
    font-size: 25px;
    background: none;
    padding-right: 8px;
    float: right;
    border: none;
  }
}

.sidebar .content {
  padding: 20px;
}

/* Main Content Styles */
.main-content {
  padding: 20px;
  background-color: #f4f4ff;
  overflow: auto;
  transition: margin-left 0.3s ease;
  scrollbar-width: none;
}

.sidebar.collapsed~.main-content {
  margin-left: 60px;
}

.sidebar:not(.collapsed)~.main-content {
  margin-left: 250px;
}

/* Responsive Grid Layout */
.App {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100vh;
}


.sidebar .sidebar-list {
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
  /* Adjust spacing */
}

.sidebar .sidebar-list li {
  /* display: flex; */
  align-items: center;
  padding: 1px 20px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: max-content;
  white-space: nowrap;
}

.sidebar .sidebar-list li:hover {
  color: cornflowerblue;
  border-radius: 20px;
  /* Highlight on hover */
}

.sidebar .sidebar-list li .icon {
  margin-right: 10px;
  min-width: 24px;
  font-size: 1.5em;

}

.sidebar.collapsed .sidebar-list li .icon {
  margin-left: -20px;
  transition: margin 0.3s ease, opacity 0.3s ease;
}

.sidebar .sidebar-list li span {
  white-space: nowrap;
}

.sidebar.collapsed .sidebar-list li span {
  display: none;
}

.sidebar .sidebar-list li a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar .sidebar-list li a:hover {
  text-decoration: none;
}

.home {
  background-color: white;
  border-radius: 20px;
  height: 30px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .App {
    display: flex;
    flex-direction: column;
    /* Stack sidebar and content vertically */
  }

  .sidebar {
    position: absolute;
    transform: translateX(-100%);
    width: 250px;
    /* Sidebar full width when overlayed */
    height: 100vh;
    transition: transform 0.3s ease;
  }

  .sidebar.collapsed {
    transform: translateX(-80%);
    /* Hide sidebar */
  }

  .sidebar:not(.collapsed) {
    transform: translateX(0);
    /* Show sidebar */
  }

  .sidebar .toggle-button {
    top: 15px;
    right: -10px;
    background-color: cornflowerblue;
    z-index: 1100;
    display: block;
    transform: translateX(0);
    position: fixed;
  }

  .main-content {
    margin-left: 0;
    flex: 1;
    transition: margin 0.3s ease, opacity 0.3s ease;
    /* margin-top: -100%; */
  }
}

.sidebar.collapsed~.main-content {
  margin-left: 60px;
}

.sidebar .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #fff;
  margin: 10px 0;
  border-radius: 20px;
}

/* .sidebar .logo img {
  max-height: 40px;
  max-width: 100%;
} */

.sidebar .logo img.collapsed-logo {
  max-height: 30px;
}


/* timeline css */

/* The actual timeline (the vertical ruler) */
.main-timeline-4 {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: #bbb;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-4 {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-4::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -11px;
  background-color: #bbb;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left-4 {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right-4 {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left-4::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid rgba(37, 117, 252, 1);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgba(37, 117, 252, 1);
}

/* Add arrows to the right container (pointing left) */
.right-4::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid rgba(245, 87, 108, 1);
  border-width: 10px 10px 10px 0;
  border-color: transparent rgba(245, 87, 108, 1) transparent transparent;
}

/* Fix the circle for containers on the right side */
.right-4::after {
  left: -14px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {

  /* Place the timelime to the left */
  .main-timeline-4::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-4 {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-4::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left-4::after,
  .right-4::after {
    left: 18px;
  }

  .left-4::before {
    right: auto;
  }

  /* Make all right containers behave like the left ones */
  .right-4 {
    left: 0%;
  }
}

.gradient-custom {
  /* fallback for old browsers */
  background: #6a11cb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right,
      rgba(106, 17, 203, 1),
      rgba(37, 117, 252, 1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right,
      rgba(106, 17, 203, 1),
      rgba(37, 117, 252, 1));
}

.gradient-custom-4 {
  /* fallback for old browsers */
  background: #f093fb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to left,
      rgba(240, 147, 251, 1),
      rgba(245, 87, 108, 1));

  background: linear-gradient(to left,
      rgba(240, 147, 251, 1),
      rgba(245, 87, 108, 1));
}

/* login page */

/* body {
  background-color: #94d9cd;
} */

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 4px solid transparent;
  border-top: 4px solid transparent;
  border-image: linear-gradient(brown, #ff0000, #00ff00);
  border-image-slice: 1;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-date-picker {
  width: 280px;
  height: 5.2vh;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out;
  box-shadow: #495057;
}

.form-control {
  height: 38px;
  box-shadow: #495057;
}


/* pro-sidebar */

/* .pro-sidebar {
  color: white;
  height: 100vh;
  background: linear-gradient(165deg, #6f6af8, #4f186b);
}

.pro-sidebar .pro-sidebar-inner {
  background: inherit;
}

.pro-sidebar .pro-menu-item.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}

.pro-sidebar .pro-icon-wrapper {
  background-color: transparent;
}

.pro-sidebar .pro-inner-item {
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pro-sidebar .pro-inner-item:hover {
  color: cornflowerblue;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}

.pro-sidebar .pro-inner-list-item {
  padding: 10px 20px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #fff;
  margin: 10px 0;
  border-radius: 20px;
}

.logo img {
  max-height: 40px;
  max-width: 100%;
}

.toggle-button {
  position: absolute;
  top: 10px;
  right: -10px;
  width: 35px;
  height: 25px;
  background-color: cornflowerblue;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 20;
  border-radius: 20px;
}

.help-section {
  padding: 10px;
  color: black;
}

.help-section .user-info {
  display: flex;
  align-items: center;
  background: whitesmoke;
  padding: 10px;
  border-radius: 10px;
} */

/* pro-sidebar ended */

ul {
  list-style-type: none;
  padding: 0;
}

.menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  margin-right: 10px;
  vertical-align: middle;
}

.menu-item span {
  margin-left: 5px;
}

.submenu {
  display: none;
  padding-left: 20px;
}

.submenu li {
  margin-bottom: 8px;
}

.submenu .icon {
  margin-right: 8px;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input-wrapper input {
  padding-right: 30px;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  cursor: pointer;
}

.children {
  list-style: none;
  padding: 2px;
  margin-left: -10px;
  display: none;
  border-radius: 10px;
  background: #d40c158f;
  background: hsla(0, 0%, 100%, .07);
  /* border-top: solid 1px #ffffff26; */
}

li {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 18px;
}

/* Api user dashboard cards */
.info-box {
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
  border-radius: .25rem;
  background: #35364b;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  min-height: 80px;
  padding: .5rem;
  position: relative;
  width: 100%;
  color: white;
}

.info-box .progress {
  background-color: rgba(0, 0, 0, .125);
  height: 2px;
  margin: 5px 0
}

.info-box .progress .progress-bar {
  background-color: #fff
}

.info-box .info-box-icon {
  border-radius: .25rem;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.875rem;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 70px
}

.info-box .info-box-icon>img {
  max-width: 100%
}

.info-box .info-box-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 120%;
  -ms-flex: 1;
  flex: 1;
  padding: 0 10px
}

.info-box .info-box-number {
  display: block;
  margin-top: .25rem;
}

.info-box .info-box-text,
.info-box .progress-description {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}


@media screen and (max-width: 600px) {
  .upiImage img {
    width: 100%;
  }

  .upiModal {
    padding: 10px;
  }
}

#ipBtn:hover {
  color: blue;
  background: beige;
}


/* landing page css */

#navbar {
  background-color: #fff;
}

/* src/LandingPage.css */

/* Styles for the navbar and main content */
.navbar {
  width: 100%;
  padding: 1rem 2rem;
  background-color: #f9f4f4;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed !important;
  /* Make the navbar fixed */
  top: 0;
  /* Stick to the top */
  left: 0;
  /* Stick to the left edge */
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-left {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 50rem;
}

.navbar a {
  color: black;
  text-decoration: none;
  margin: 0 1rem;
  font-size: 1rem;
}

.navbar a:hover {
  color: red;
}

.login-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: skyblue;
  color: #333;
  transition: background 0.3s, color 0.3s;
}

.login-button:hover {
  background: #555;
  color: white;
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  /* background: linear-gradient(135deg, #ff6f61, #de3c4b); */
  /* background-image: url('../public/assets/images/rm141.jpg'); */
  background-size: contain;
  background-repeat: no-repeat;
  color: black;
  text-align: center;
  padding-top: 5rem;
}

.home-section {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem;
}


.home-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.home-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: darkcyan;
}

.start-button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #f4f4ff;
  color: #ff735c;
  transition: background 0.3s, color 0.3s;
}

.start-button:hover {
  background: #ff735c;
  color: white;
}

.upi-animation {
  position: relative;
  overflow: hidden;
  height: 530px;
  border-radius: 20px;
}

.upi-animation img {
  width: 100%;
  height: auto;
}

.upi-animation:hover img {
  transform: scale(1.1);
}

.landing-page h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.landing-page p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.landing-page button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  color: #de3c4b;
  transition: background 0.3s, color 0.3s;
}

.landing-page button:hover {
  background: #de3c4b;
  color: white;
}

.gradient-custom-3 {
  background: #84fab0;
  background: -webkit-linear-gradient(to right, rgba(132, 250, 176, 0.5), rgba(143, 211, 244, 0.5));
  background: linear-gradient(to right, rgba(132, 250, 176, 0.5), rgba(143, 211, 244, 0.5))
}

.gradient-custom-4 {
  background: #84fab0;
  background: -webkit-linear-gradient(to right, rgba(143, 211, 244, 1), rgba(132, 250, 176, 1));
  background: linear-gradient(to right, rgba(132, 250, 176, 1), rgba(143, 211, 244, 1))
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .logo {
    margin-right: 1rem;
  }

  .navbar a {
    margin: 0 0.5rem;
  }

  .landing-page {
    width: 100%;
    background: cover;
    padding: 30px;
    overflow: scroll;
  }

  .home-section {
    flex-direction: column;
    padding: 1rem;
    width: 50%;
    margin-left: 25%;
  }

  .home-content {
    max-width: 100%;
    text-align: center;
  }

  .home-content h1 {
    font-size: 1.5rem;
  }

  .home-content p {
    font-size: 1rem;
  }

  .upi-animation {
    height: auto;
    width: 100%;
    margin-top: 1rem;
  }

  .card {
    width: 100%;
    overflow: scroll;
  }

  #homepage-content {
    margin-top: 30%;
  }

  #scanSteps {
    width: 350px;
  }
}

.how-it-works-section {
  width: 100%;
  padding: 2rem;
  /* background-color: #ffff; */
  border-radius: 20px;
  color: black;
  text-align: center;
  line-height: 4;
  cursor: pointer;
}

.steps {
  /* list-style-type: auto; */
  text-wrap: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.step {
  margin-bottom: 1rem;
  text-align: left;
}


@media (min-width: 768px) {
  .steps {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }

  .step {
    width: 20%;
  }

}

.custom-card {
  height: 100% !important;
  border-radius: 20px;
}

.card-content {
  display: flex;
}

.card-img {
  width: 40% !important;
}

.card-text {
  text-align: left;
  margin-top: 15px;
  padding-left: 1rem;
}

.card-title {
  font-weight: bold;
}

.card-description {
  font-size: 17px;
}

.totalCount {
  display: flex;
}

@media (max-width: 768px) {
  .card-content {
    flex-direction: column;
    align-items: center;
  }

  .card-img {
    width: 100%;
    margin-bottom: 1rem;
  }

  .card-text {
    text-align: center;
    padding-left: 0;
  }

  .custom-card {
    width: 50%;
    margin-left: 25%;
  }

  .features {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }

  .totalCount {
    display: grid;
  }
}

.crm_body_bg {
  height: 150vh;
}

@media (max-width: 786px) {
  .crm_body_bg {
    height: max-content;
  }
}